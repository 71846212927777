div#avb-application-list {
  thead {
    th {
      color: #fff;
      background-color: var(--blue-grey);
    }
  }
  tbody {
    .avb-expand-col {
      width: 50px;
    }
    .avb-help-request-col {
      padding: 0 4px;
    }
    .avb-unit-col a {
      color: var(--blue-grey);
      font-weight: bold;
      text-decoration: none;
    }
    .avb-unit-col {
      width: 220px;
      padding: 12px 16px 0px 16px;
      color: #003366;
      font-weight: bold;
    }
  }
  .avb-primary-name-col {
    font-size: 14px;
  }
  .avb-status-col {
    width: 200px;
  }
  .avb-move-in-col {
    width: 150px;
  }
  .avb-group-outcome-col {
    width: 150px;
  }
  .avb-expanded-row {
    background-color: #f8f9fa;
    border: 0;
    border-style: hidden;
  }
  .avb-hold-clock-col {
    width: 500px;
    .hold-clock-color-green {
      color: #52c41a;
    }
    .hold-clock-color-yellow {
      color: #fadb14;
      font-weight: bold;
    }
    .hold-clock-color-red {
      color: #f5222d;
      font-weight: bold;
    }
  }
  .ant-table-expanded-row {
    .avb-status-col {
      width: 210px;
    }
    .avb-help-request-col {
      width: 30px;
    }
  }
}

.pill-housing-voucher {
  background-color: #ed6c02;
  color: white;
  margin-left: 1em;
}
