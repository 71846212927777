div#avb-actions-required {
  padding: 15px 0;
  background-color: var(--blue-grey);
  margin: 0 0 15px 0;
  text-align: left;
  overflow: auto;
  .action-heading {
    font-family: "ProximaNova-Semibold";
    font-size: 16px;
    color: white;
    padding-left: 30px;
    margin-bottom: 5px;
    span {
      margin-left: 12px;
    }
    .flag-icon {
      transform: scale(1.5);
    }
  }
  .actions-container {
    height: 65px;
    display: flex;
    align-items: center;

    .outer-action-container {
      padding: 0 5px;
      .action-container {
        font-family: "ProximaNova-Bold";
        display: flex;
        align-items: center;
        padding: 8px;
        margin: 0 10px;
        line-height: 1.1;
        border-radius: 5px;

        .value {
          font-size: 42px;
          color: white;
        }
        .text {
          font-size: 16px;
          color: white;
          margin-left: 10px;
        }
        &.selected,
        &:hover {
          background-color: white;
          .value {
            color: var(--blue-grey);
          }
          .text {
            color: var(--blue-grey);
          }
          cursor: pointer;
        }
      }
    }
    .outer-action-container:not(:last-child) {
      border-right: 0.5px solid white;
    }
  }
}
